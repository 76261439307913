import * as React from 'react'
import { Component } from 'react'
import { Row, Col, Button, Card, Image }from 'react-bootstrap'
import { LinkContainer } from "react-router-bootstrap"

 class DiscoveryWorld extends Component {

    componentDidMount() {
      window.scrollTo(0, 0)
    }

    render() {
        return (
        <div>
            <a target="_blank" rel='noopener noreferrer' href='https://www.discoveryworld.org/things-to-do/exhibits/rockwell-automation-automation-everywhere/'><h2>UX Analysis (Discovery World: Automation Everywhere)</h2></a>
            <Row>
              <Col xs={12}>
                <Image style={{maxWidth: 'inherit'}} className='mb-4' src={require('../assets/images/discovery_image_2.jpg')} rounded fluid></Image>
              </Col>  
            </Row>
            <h3>Summary</h3>
            <p>During the Fall of 2019, two of my classmates and I were tasked with assessing the user experience of an exhibit at the Discovery World museum in Milwaukee, Wisconsin. The exhibit we chose was the Automation Everywhere exhibit, sponsored and presented by Rockwell Automation, which caught our attention as engineers. We were given two weeks to gather data and deliver our analysis, conclusions, and recommendations through a written formal report.</p>
            <p>As part of my work for this project, I visited the exhibit and gathered data from observations and interviews with visitors, most of whom were parents and their young children. In addition, I discussed my observations with the rest of the team and wrote a third of the final report.</p>
            <p>One of the biggest challenges with this assignment was that we only had time for one visit to the exhibit. This meant that we could not afford to miss anything during our observations without risking providing an inaccurate assessment. Additionally, we had to be extra careful not to invade the visitors’ privacy and raise concerns with parents.</p>
            <h3>Reflection</h3>
            <p>For this project, I used skills learned in previous UX design assignments and gathering requirements for software projects to accurately and precisely define the user profiles for the exhibit. We knew that even though the exhibit was advertised for a very wide range of people, anyone 6 years and older, the machines and activities at the exhibit were intended to inspire older children and young teenagers to join the industry that created them.</p>
            <p>We also did a very good job in applying the UX concepts and principles to better understand the design philosophy behind the exhibit’s design, and to deliver our own recommendations based on the user profiles and the ways in which we saw people actually interacting with the exhibit.</p>
            <p>We did, however, leave much to be desired with the formatting and consistency of our written report. We spent about three fourths of our time gathering data and discussing our observations, which left only a couple of days for us to write out the report. Each of us wrote our designated sections and after we were all finished, we spent only about a day revising our work and checking each other’s work. This caused inconsistencies with voice and formatting.</p>
            <p>All in all, we learned a lot about user motivations, visual design, signaling and affordance, and gathering data from observations and interviews. I am happy with the work we did, as even with our shortcomings we were able to provide value for our reader through our insights and recommendations.</p>
            <br></br>
            <h4>Other Projects</h4>
            <Row>
              <LinkContainer to='/projects/portfolio-wireframes'>
                <Col md ={4}>
                  <Card className='bc-project-card' style={{ width: '18rem' }}>
                    <Card.Img variant="top" src={require("../assets/images/portfolio_wireframes.png")} />
                    <Card.Body>
                      <Card.Title>Portfolio Wireframes</Card.Title>
                      <Card.Text>
                      The initial wireframes and sitemap for this site!
                      </Card.Text>
                    </Card.Body>
                    <Button href='/projects/portfolio-wireframes'>Learn More</Button>
                  </Card>
                </Col>
              </LinkContainer>
            </Row>
          </div>
        )
    }
 }

export default DiscoveryWorld