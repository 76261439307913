import * as React from 'react'
import { Component } from 'react'
import { Row, Col, Card, Button }from 'react-bootstrap'
import { LinkContainer } from "react-router-bootstrap"

 class Projects extends Component {
    render() {
        return (
          <div>
            <h2>Projects</h2>
            <p className='bc-text'>Below are a few projects I have worked on while pursuing my UX minor at MSOE.</p>
            <Row>
              <LinkContainer to='/projects/discovery-world'>
                <Col md={4}>
                  <Card className='bc-project-card' style={{ width: '18rem' }}>
                    <Card.Img variant="top" src={require('../assets/images/discovery_image.jpg')} />
                    <Card.Body>
                      <Card.Title>Discovery World</Card.Title>
                      <Card.Text>
                      UX analysis of a Discovery World exhibit.
                      </Card.Text>
                    </Card.Body>
                    <Button href='/projects/discovery-world'>Learn More</Button>
                  </Card>
                </Col>
              </LinkContainer>
              <LinkContainer to='/projects/portfolio-wireframes'>
                <Col md ={4}>
                  <Card className='bc-project-card' style={{ width: '18rem' }}>
                    <Card.Img variant="top" src={require("../assets/images/portfolio_wireframes.png")} />
                    <Card.Body>
                      <Card.Title>Portfolio Wireframes</Card.Title>
                      <Card.Text>
                      The initial wireframes and sitemap for this site!
                      </Card.Text>
                    </Card.Body>
                    <Button href='/projects/portfolio-wireframes'>Learn More</Button>
                  </Card>
                </Col>
              </LinkContainer>
            </Row>
          </div>
        )
    }
 }

export default Projects