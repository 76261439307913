import * as React from 'react'
import { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faGithubSquare, faGitlab, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons'

 class Contact extends Component {
    render() {
        return (
          <div>
            <h2>Get In Touch</h2>
            <Row>
              <Col md={8}>
                <h3>Email</h3>
                <p className="bc-text">camposbotellob@msoe.edu</p>
                <br></br>
                <h3>Phone</h3>
                <p className="bc-text">(414) 739-8774</p>
                <br></br>
              </Col>
              <Col md={4}>
                <h3>Other Media</h3>
                <Row>
                  <Col xs={2}>
                    <a target="_blank"  rel='noopener noreferrer' className={'text-secondary'} href='https://www.linkedin.com/in/camposbotellob/'><FontAwesomeIcon icon={faLinkedin} size='2x'></FontAwesomeIcon></a>
                  </Col>
                  <Col xs={2}>
                    <a target="_blank"  rel='noopener noreferrer' className={'text-secondary'} href='https://github.com/camposbotellob'><FontAwesomeIcon icon={faGithubSquare} size='2x'></FontAwesomeIcon></a>
                  </Col>
                  <Col xs={2}>
                    <a target="_blank"  rel='noopener noreferrer' className={'text-secondary'} href='https://gitlab.com/camposbotellob'><FontAwesomeIcon icon={faGitlab} size='2x'></FontAwesomeIcon></a>
                  </Col>
                  <Col xs={2}>
                    <a target="_blank"  rel='noopener noreferrer' className={'text-secondary'} href='https://youtube.com/ThePianoL'><FontAwesomeIcon icon={faYoutubeSquare} size='2x'></FontAwesomeIcon></a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )
    }
 }

export default Contact