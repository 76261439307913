import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Header from './Header'
import Home from './components/Home'
import Projects from './components/Projects'
import UXCourse from './components/UXCourse'
import Contact from './components/Contact'
import MyDearestValentine from './components/MyDearestValentine'
import {
  BrowserRouter,
  Route
} from "react-router-dom";
import {
  Container
} from "react-bootstrap";
import Wireframes from "./components/Wireframes";
import DiscoveryWorld from "./components/DiscoveryWorld";

// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

export default function PortfolioSite() {
  return (
    <div>
      <BrowserRouter>
        <div className='bc-header'> <Header/> </div>
        <Container>
          <div className='bc-main shadow p-3 mb-5 bg-white rounded'>
            <h1 id="bc-name" className="mb-4">Brandon Campos</h1>
            <Route exact path="/" component={Home} />
            <Route exact path="/about" component={Home} />
            <Route exact path="/projects" component={Projects} />
            <Route exact path="/ux-course" component={UXCourse} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/projects/portfolio-wireframes" component={Wireframes} />
            <Route exact path="/projects/discovery-world" component={DiscoveryWorld} />
            <Route exact path="/mydearest/2020_02_14" component={MyDearestValentine} />
          </div>
        </Container>
      </BrowserRouter>
    </div>
  )
}