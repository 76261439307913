import * as React from 'react'
import { Component } from 'react'
import { Row, Col, Image }from 'react-bootstrap'

 class Home extends Component {
    render() {
        return (
          <div>
            <Row>
              <Col md={4}>
                <Image className='mb-4' src={require('../assets/images/brandon_profile.jpg')} rounded fluid></Image>
              </Col>
              <Col md={8}>
                <h3>Hello there,</h3>
                <p className='bc-text'>I am a Software Engineering student at the Milwaukee School of Enginering (MSOE), and I am pursuing minors in Mathematics and in User Experience.</p>
                <p className='bc-text'>Currently, I am a tutor at MSOE for various software and math courses. I also work as a software developer subcontractor for Iero LLC, and will be joining the team at Epic Systems on July 2020.</p>
                <p className='bc-text'>During my free time, I like playing board games with friends and family. I also taught myself to play the piano and am always looking for new songs to learn, a few of which I have even uploaded to my YouTube channel.</p>
              </Col>
            </Row>
          </div>
        )
    }
 }

export default Home