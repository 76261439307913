import * as React from 'react'
import { Component } from 'react'
import { Button } from 'react-bootstrap'

 class UXCourse extends Component {
    componentDidMount() {
        this.backToTop()
    }

    backToTop() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
          <div>
              <h2>Portfolio Design Rationale</h2>
              <ul>
                  <li>
                      <strong>What choices did you make and why? For example, did you make a video for your home page because you knew that moving pictures would grab visitors’ attention?</strong>
                      <p>I did not make a video for my website because I believed it would be distracting and it would have to cover most of the content in the rest of the website, at which point I would not need a website in the first place.</p>
                      <p>Instead I aimed to design a site that would show users the way to get directly to what they are looking for from any page of the website. This effectively makes any page in my site a good landing page and simultaneously provides quick access to the rest of the site.</p>
                      <p>To accomplish this, the navbar clearly says where everything is located, and the project pages point towards other projects, leading the user to the next page.</p>
                      <p>Another choice worth mentioning is that I wanted to link to external resources, but I did not want users abandoning my site after they clicked on these links, so they open new tabs instead of replacing the tab with my site in it.</p>
                  </li>
                  <li>
                      <strong>How did you take potential visitors into account? Did you make user profiles(personas) and design for them?</strong>
                      <p>I did not make personas for my potential visitors, at least not yet. So far, I have used feedback from my interviews and from advice from recruiters and guest speakers to derive expectations and motivations for which to design for.</p>
                      <p>I also considered the fact that nowadays mobile support is critical, which is why I took the extra time to make sure that everything worked well for both traditional monitors and smaller mobile screens.</p>
                      <p>Additionally, users can easily become frustrated with long loading times, which is why I decided to develop this website as a Single-Page Application. This means that there is only one request to the server and every navigation on the user's end is handled locally, resulting in extremely fast responses. This is also the reason why I chose not to use a modern website editor, such as Wix or Weebly: I wanted full control over every single detail in my website, as well as the experience of purchasing my own domain, and deploying my very own website.</p>
                  </li>
                  <li>
                      <strong>Which motivations (as discussed in Marsh) did you use in designing your portfolio? If you did not use any in your design work, then explain why not. It is OK not to use any as long as you have a good reason for your decision.</strong>
                      <p>I used understanding because I concluded that someone that has chosen to take a look at my portfolio website will probably have at least a small interest in getting to know me better. With that said, I am aware that my website has to fuel that interest and tie that back with a motivation with association, both my desire to be a part of the software developer community and a company, and their desire to connect with other members of that community.</p>
                      <p>In my homepage I talk a little about myself and what I do in my free time because that might inspire curiosity, particularly about my YouTube channel. This still serves the goal of the portfolio, because my few videos there are also evidence of my design skills across the years.</p>
                  </li>
              </ul>
              <br></br>
              <h2>UX1400 Course Reflection</h2>
              <ul>
                  <li>
                      <strong>What did you learn about UX design?</strong>
                      <p>I learned about what motivates people to act and how we can use advantage of those motivations to encourage certain behaviors and interactions. I learned about information architecture and how to consider the requirements and purpose of a product to choose the right organization. Finally, I learned about what kinds of data we can gather and use to improve our designs in an agile way.</p>
                  </li>
                  <li>
                      <strong>How did your ideas about UX change over the course of the quarter?</strong>
                      <p>I initially had felt that UX was more of a filter or lens to be applied to a project, as a sort of check, to determine if it was ready for release. Now I can see how UX can work as a process by which ideas and designs can evolve before, during, and after development, to improve a product or project’s efficiency at accomplishing the involved party’s goals.</p>
                  </li>
                  <li>
                      <strong>How does this course relate to your education and career aspirations? If you are a non-major, then talk about how UX relates to your major area of study. If you are a UX major, then you might talk about the relationship between this course and other UX courses. In general, everyone in class should be able to say how this course adds value to your candidacy for internships and entry-level positions.</strong>
                      <p>User experience plays a very important role in software development, specially for front-end development. Many of the techniques for gathering software requirements and for creating innovative solutions using software systems are the same that we study here in UX courses.</p>
                      <p>Developers who can take a step back from the code and understand the human needs behind the project they are working on, are more likely to create solutions that effectively address the problem at hand.</p>
                  </li>
              </ul>
              <Button onClick={this.backToTop} block>Back To Top</Button>
          </div>
        )
    }
 }

export default UXCourse