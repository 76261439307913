import * as React from 'react'

import {
    Navbar,
    Nav,
    Container
} from "react-bootstrap";

import { LinkContainer } from "react-router-bootstrap"

const Header: React.FC = () => (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>
            <img
              alt=""
              src={require("./assets/images/bc-logo.png")}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' '}
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <LinkContainer to="/about"><Nav.Link>About</Nav.Link></LinkContainer>
            <LinkContainer to="/projects"><Nav.Link>Projects</Nav.Link></LinkContainer>
            <LinkContainer to="/ux-course"><Nav.Link>UX Course</Nav.Link></LinkContainer>
            <LinkContainer to="/contact"><Nav.Link>Contact</Nav.Link></LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
  export default Header