import * as React from 'react'
import { Component } from 'react'

 class MyDearestValentine extends Component {
    render() {
        
        let text = [
            "¡Feliz Día de San Valentín! ¿Te gusta mi letra digital? Creo que es un poco más legible, personalmente. Aquí tengo control absoluto sobre cada detalle del producto final. Curiosamente, no voy a aprovechar todo este poder a su máximo esplendor porque, como ya te has de imaginar, estoy escribiendo esto apenas horas antes de que le puedas poner tus preciosos ojitos encima. Pero, en mi defensa, apenas fue hace unas horas que se me ocurrió usar este como mi medio.",

            "Espero que te haya gustado la canción; para mi fue algo realmente maravilloso revisitarla. Verás, desde que estoy viviendo aquí, mi piano ha sido mi principal escape de la vida americana. No solamente eso, siempre lo he usado como manera de mostrar afecto. Son pocas las veces que he decidido aprenderme alguna canción solo porque me interesa; casi siempre lo hago con intención de obsequiar una parte de mi alma a alguien. De hecho, cuando no tengo a quien me inspire, suelo dejar de tocar. Suelo dejar de sentirme como una persona creativa.",
            
            "Pero no es una exageración decir que por los últimos tres años has sido mi inspiración más grande, mi más querida. Cuando me pongo metas que siento que te pueden causar al menos una sonrisa, aunque sea solo una rebanadita de felicidad, siento que nada me puede detener para cumplirlas. Y no creas que las cosas se quedan así. Claro que no. Cada vez aspiro a cosas más grandes y cada vez me esmero más por realmente hacerte feliz.",
            
            "Hace poco más de un mes me diste una pequeña probada de lo que se siente estar a tu lado y sentir tu cariño. Ahora por las noches no puedo evitar cerrar los ojos y tratar de recordar esa hermosa sensación de tu cuerpo ligeramente recargado sobre el mío, con nuestras manos entrelazadas. Pero más que nada, no sé cómo explicarte cuanto deseaba besarte y lo mucho que sueño volver a hacerlo. Ahora de todo eso me quedan solo recuerdos, pero son de mis más preciados.",
            
            "Quiero más, mi amor.",
            
            "Me pone algo nervioso pensar en el tiempo que falta para volver a verte, y el tiempo que va a faltar después. Pero yo estaría realmente tonto si permitiera que eso me disuadiera. Así que seguiré tratando de mantenerte en duda: no sobre sí te quiero o no, sino sobre qué es lo que estoy tramando para volvértelo a demostrar.",
            "",
            "Con amor,",
            "Brandon"
        ]
        return (
          <div className="lg-p-5">
              
              <h4 style={{textAlign: 'right'}}>14 de febrero de 2020</h4>
              <iframe className="my-5" style={{margin: 'auto', display: 'block'}} width="80%" height="315" src="https://www.youtube.com/embed/vduYtnTtwyg" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              <h4>Mafer, <i>my dearest</i>,</h4>
              
              {
                  text.map((element: String) => {
                      return <p style={{fontSize: '1rem'}} className='bc-text'>{element}</p>
                  })
              }
          </div>
        )
    }

    componentDidMount() {
        let body = document.getElementById('body')
        let name = document.getElementById('bc-name')
        if (name != null)
          name.remove()
        if (body != null) {
          body.setAttribute('style', 'background-color: #7F1B33;')
          body.setAttribute('class', '')
        }
    }

    componentWillUnmount() {
        let body = document.getElementById('body')
        if (body != null){
            body.setAttribute('style', '')
          body.setAttribute('class', 'bg-secondary')
        }
    }
 }

export default MyDearestValentine