import * as React from 'react'
import { Component } from 'react'
import { Row, Col, Button, Card, Image }from 'react-bootstrap'
import { LinkContainer } from "react-router-bootstrap"

 class Wireframes extends Component {
    componentDidMount() {
      window.scrollTo(0, 0)
    }
    
    render() {
        return (
          <div>
            <a target="_blank"  rel='noopener noreferrer' href='https://xd.adobe.com/view/c33ecb1e-2afc-4ff6-68e7-86335e3121b7-b50f/'><h2>UX Portfolio Wireframes</h2></a>
            <Row>
              <Col xs={12}>
                <Image style={{maxWidth: 'inherit'}} className='mb-4' src={require('../assets/images/portfolio_wireframes.png')} rounded fluid></Image>
              </Col>  
            </Row>
            <h3>Summary</h3>
            <p>During the Fall of 2019, I was tasked with creating a UX portfolio website for myself, and this was the result. As a software engineering student at MSOE, I had worked on few web development projects before, so the technical process was not unfamiliar to me. This time, however, the focus was on visual design and creating the right information architecture. </p>
            <p>Before I even began writing code or content for my site, I designed the information architecture and layout using wireframes. I was given one week to create a site map and the wireframes. Of course, this was an individual project, but I had some help from my classmates who peer reviewed my work.</p>
            <p>I used Adobe XD, a tool I was already familiar with, to create the wireframes, so the biggest challenge was not creating them as much as it was defining the target audience and design for them specifically.</p>
            <h3>Reflection</h3>
            <p>I used many of the skills I had learned in my user interface design class to create the wireframes for this site. For example, I already knew how to use Adobe XD, so I was able to make certain elements have a fixed position and make other elements scroll behind others, and it did not take me long to finish my design once I knew what I wanted to do.</p>
            <p>In class we had learned information architecture, and from certain guest speakers I also had learned that employers and recruiters often spend only a few minutes reviewing portfolio websites. With this information, I designed my website so that most pages are accessible from any other page in the site.  Additionally, I used what I learned in my visual design class to make sure that the elements in my wireframes were following the contrast, repetition, alignment and proximity principles.</p>
            <p>Perhaps the most important skills I learned when working on this project were to keep in mind the users’ goals when designing interfaces and organizing data. As I said before, I have worked on a few web projects before and I continue to do so to this day, so full-stack development and front-end development are a big part of my daily life. As such, I have noticed many more opportunities to make small changes to the visuals and the phrasing of my interfaces that go a long way in improving the overall user experience of my audience.</p>
            <h4>Other Projects</h4>
            <Row>
              <LinkContainer to='/projects/discovery-world'>
                <Col md={4}>
                  <Card className='bc-project-card' style={{ width: '18rem' }}>
                    <Card.Img variant="top" src={require('../assets/images/discovery_image.jpg')} />
                    <Card.Body>
                      <Card.Title>Discovery World</Card.Title>
                      <Card.Text>
                      UX analysis of a Discovery World exhibit
                      </Card.Text>
                    </Card.Body>
                    <Button href='/projects/discovery-world'>Learn More</Button>
                  </Card>
                </Col>
              </LinkContainer>
            </Row>
          </div>
        )
    }
 }

export default Wireframes